import React from "react";
import Seo from "../../components/seo";
import { Link } from "gatsby";
import useIsInViewport from "../../hooks/useIsInViewport";

/* START: images import */
import marticka from "../../img/approach/marticka.jpg";
import standards from "../../img/approach/standards.jpg";
import code from "../../img/approach/code.jpg";
/* END: images import */

const Intro = ({ setObserverRef }) => (
    <div ref={setObserverRef} data-observer={0}>
        <article className="article" id="how">
            <h2 className="line sm animate-1">Malý velký tým</h2>
            <p className="animate-2">
                Jsme parta zkušených programátorů. K tomu máme kontakty na prověřené kolegy, které můžeme oslovit v případě objemnější zakázky nebo technologie, kterou my sami
                neovládáme na 100 %.
            </p>
        </article>
        <article className="article animate-3" id="extending">
            <h2 className="line animate-4">Půjčíme vám ruce</h2>
            <p className="animate-5">
                Dokážeme vám pomoci i v případě, kdy jen potřebujete rozšířit svůj tým o pár specialistů. Máme zkušenosti s prací přímo u klientů, v agenturách v Čechách a Anglii,
                ale umíme spolehlivě fungovat i z naší základny na Letné. Umíme se naladit a přizpůsobit, ať už jde o spolupráci s developery nebo designéry.
            </p>
        </article>
        <article id="working" className="clearfix animate-6">
            <article className="article animate-7">
                <h3 className="animate-8">Spolupráce s vývojáři</h3>
                <p className="animate-9">Jako podpora vašich technických týmů upravíme naši metodologii, technologii či délku sprintů podle potřeb.</p>
            </article>
            <article className="article animate-7">
                <h3 className="animate-8">Spolupráce s designéry</h3>
                <p className="animate-9">Naším cílem je, aby se i ten nejdrobnější detail vašeho designu promítl do výsledného produktu.</p>
            </article>
        </article>
    </div>
);

const Planning = ({ indx, setObserverRef }) => (
    <article className="article" id="planning" ref={setObserverRef} data-observer={indx}>
        <h2 className="line sm animate-1">Martička - náš virtuální pedant</h2>
        <img className="animate-5" src={marticka} alt="Marticka.cz" />
        <p className="animate-2">
            Dokážeme pracovat jak s předem definovaným rozpočtem, tak s průběžnou evidencí nákladů při dlouhodobé spolupráci. Za tím účelem jsme vytvořili vlastní nástroj, jehož
            prostřednictvím můžete on-line sledovat rozpočet projektu. Kdykoliv se do{" "}
            <a href="https://www.marticka.cz/" target="_blank" rel="noopener noreferrer">
                Martičky
            </a>{" "}
            přihlásíte, máte jasno, co se na vašem projektu šustlo.
        </p>
        <h2 className="line sm animate-3">*hit happens</h2>
        <p className="animate-4">
            Podle našich zkušeností se každý projekt odchýlí od sebelepšího plánu. Naučili jsme proto počítat s tím, že se v průběhu projektu objeví zádrhel. Jsme díky tomu lepší
            při jejich překonávání.
        </p>
    </article>
);

const Code = ({ indx, setObserverRef }) => (
    <article className="article" id="code" ref={setObserverRef} data-observer={indx}>
        <h2 className="animate-1">Tradice vs. Trendy</h2>
        <img className="animate-2" src={code} alt="Code" />
        <p className="animate-3">
            Web jde dopředu mílovými kroky - nová verze prohlížečů je tu každých 6 týdnů. Přesto se snažíme nepodléhat slepě všem novinkám, ale spíše maximálně využít jejich
            výhody. Vždy záleží na klientovi, jeho cílové skupině a požadovaném výsledku, kterému se snažíme přizpůsobit.{" "}
        </p>
        <p className="animate-4">
            Ať už se jedná o banální testování v různých prohlížečích, zapojení nástrojů kontrolující kvalitu kódu anebo použití pokročilých testovacích frameworků jako jsou{" "}
            <a href="https://jestjs.io/" target="_blank" rel="noopener noreferrer">
                Jest
            </a>
            ,{" "}
            <a href="https://mochajs.org/" target="_blank" rel="noopener noreferrer">
                Mocha
            </a>{" "}
            či{" "}
            <a href="https://jasmine.github.io/" target="_blank" rel="noopener noreferrer">
                Jasmine
            </a>
            , pomůžeme vám najít správné nastavení pro každý případ.
        </p>
    </article>
);

const Standards = ({ indx, setObserverRef }) => (
    <article className="article" id="standards" ref={setObserverRef} data-observer={indx}>
        <h2 className="line sm animate-1">Máme rádi Scrum</h2>
        <img className="animate-3" src={standards} alt="standards" />
        <p className="animate-2">
            Přehledný systém pro plánování a sledování projektu, který je navíc velmi flexibilní, pokud potřebujete dodatečně změnit původní zadání. Uvidíte nám do kuchyně, aniž
            byste se zvedli od svého stolu.
        </p>
    </article>
);

const Experience = ({ indx, setObserverRef }) => (
    <article className="article" id="experience" ref={setObserverRef} data-observer={indx}>
        <div className="text animate-1">
            <p>
                Podívejte, <Link to="/cz/co-umime">co všechno umíme</Link>
            </p>
        </div>
    </article>
);

const halfContentSections = [Planning, Code, Standards, Experience];

const Approach = () => {
    const { setRef } = useIsInViewport();
    return (
        <>
            <Seo title="Jak pracujeme" lang="cs" />
            <div>
                <Intro setObserverRef={setRef} />
                <section className="halfContentSection">
                    {halfContentSections.map((Component, index) => (
                        <Component key={index} indx={index + 1} setObserverRef={setRef} />
                    ))}
                </section>
            </div>
        </>
    );
};
export default Approach;
